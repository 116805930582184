<template>
  <section id="tiss-guides-header">
    <div>
      <div class="nav">
        <!-- <router-link to="/faturamento-sus/lotes-enviados" tag="span" class="path"> -->
          <ArrowLeft class="icon" @click="lastPage" />
        <!-- </router-link> -->
        <b-skeleton v-if="!title" class="skeleton" width="400px" />
        <h3 v-else class="title">{{ title }}</h3>
      </div>
      <div class="subtitle">
        <div class="sumary">
          <span>Lote Nº:</span>
          <b-skeleton v-if="!summary?.batch_number" class="skeleton" width="100px" />
          <div v-else>{{ summary?.batch_number }}</div>
        </div>
        <div class="sumary" v-if="summary?.protocol">
          <span>Nº do protocolo:</span>
          {{ summary?.protocol }}
        </div>
      </div>
    </div>
    <div class="actions">
      <b-dropdown text="Gerenciar Situações" variant="primary" :disabled="disabledStatus">
        <b-dropdown-item-button v-for="(option, index) in statusOptions" :key="index" @click="updateBatches(option)">
          {{ option.label }}
        </b-dropdown-item-button>
      </b-dropdown>
      <Loading :class="{ reload: true, loading }" @click="() => !loading && $emit('reload')"/>
      <b-button variant="outline-primary" @click="toggleSidebar" title="Resumo do lote">
        <BackBurger class="burger-icon" />
      </b-button>
    </div>
  </section>
</template>
<script>
import api from '@/modules/tiss/manageGuides/api'
import { parseGuideType } from '@/modules/tiss/manageGuides/utils/guideHelper'
import { guideStatusOptions } from '@/modules/tiss/manageGuides/utils/statuses'

export default {
  name: 'guides-header',
  components: {
    Loading: () => import('@/assets/icons/loading.svg'),
    ArrowLeft: () => import('@/assets/icons/arrow-left.svg'),
    BackBurger: () => import('@/assets/icons/back-burger.svg'),
  },
  props: {
    type: String,
    id: String,
    disabledStatus: Boolean,
    loading: Boolean,
  },
  data() {
    return {
      statusOptions: guideStatusOptions,
      title: null,
      healthPlan: null,
      summary: {
        batch_number:  null,
        protocol: null,
      },
    }
  },
  async mounted() {
    this.id && this.type === 'guideItem' ? await this.getGuideHeader() : await this.getBatchHeader()
  },
  methods: {
    async getBatchHeader() {
      try {
        const { data } = await api.getBatchHeader(this.id)
        this.title = `Guias de ${parseGuideType(data.type)} | ${data.tiss_invoice.clinic_health_plan.health_plan.fantasy_name}`
        this.summary = {
          batch_number: data?.number,
          protocol: data?.protocol_number,
        }
      } catch (err) {
        this.$toast.error(err.message)
      }
    },
    async getGuideHeader() {
      try {
        const { data } = await api.getGuideHeader(this.id)
        this.title = `${data.patient.name} | Guia ${parseGuideType(data.guide_type)} Nº ${data.provider_guide}`
        this.summary = {
          batch_number: data.tiss_batch?.number,
          protocol: data.tiss_batch?.protocol_number,
        }
      } catch (err) {
        this.$toast.error(err.message)
      } 
    },
    toggleSidebar() {
      this.$root.$emit('bv::toggle::collapse', 'manage-guides-sidebar')
    },
    async updateBatches(item) {
      const { label, value } = item
      const res = await this.$swal({
        title: 'Gerenciar situações',
        html: `<div class="body-alert">Deseja realmente alterar a situação
            de todos os itens selecionados para <span>"${label}"</span>?</div>`,
        reverseButtons: true,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        confirmButtonColor: '#305BF2',
        cancelButtonText: 'Cancelar',
        customClass: {
          cancelButton: 'btn-cancel',
          confirmButton: 'btn-primary'
        },
      })

      if (res.isConfirmed) {
        this.$emit('update', value)
      }
    },
    lastPage() {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss" scoped>
#tiss-guides-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  margin-bottom: 30px;

  .title {
    display: flex;
    margin-bottom: 10px;
  }

  .path {
    color: var(--blue-600);
    cursor: pointer;
  }

  .sumary {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    font-size: 16px;
    margin-left: 30px;

    @media (max-width: 1368px) {
        font-size: 14px;  
    }

    span {
        font-weight: 700;
        color: var(--blue-600);
        margin-right: 5px;
      }
  }

  .icon {
    width: 24px;
    height: 24px;
    margin-right: 4px;
    stroke: var(--blue-500);
    cursor: pointer;
  }

  .burger-icon {
    width: 24px;
    height: 24px;
    margin-right: 4px;
    fill: var(--blue-500);
  }

  .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
}

#tiss-manage-guides {
  .dropdown-item {
    padding: 20px;
  }
}
</style>
